import axios from 'axios'

const XLSX = require('xlsx');

export async function convertCsvToExcel(url, rawFilename) {
  try {
    console.log(`Converting CSV to Excel for URL: ${url}`);
    // Fetch the CSV file from the URL
    const response = await axios.get(url);
    const csvData = response.data;
    if (csvData.length > 50_000_000) {
      triggerDownload(csvData, rawFilename);
    } else {
      // Convert CSV data to a worksheet
      const wb = XLSX.read(csvData, { type: "string" });
      const opts = { compression: true, bookType: 'xlsx' };
      XLSX.writeFile(wb, `${rawFilename}.xlsx`, opts);
    }
  } catch (error) {
    console.error('Error fetching or converting CSV:', error);
  }
}

export function triggerDownload(content, rawFilename) {
  const blob = new Blob([ content ], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${rawFilename}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
