import React, { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { dashboardData } from "../BffClient"
import { ModelRow } from './ModelRow'

const ModelsTable = ({ selectedMarket, selectedLanguages, selectedRanges }) => {

  const [ page, setPage ] = React.useState(0)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(500)
  const [ slicedData, setSlicedData ] = React.useState([])
  const [ data, setData ] = React.useState([])
  const [ modelAggregation, setModelAggregation ] = React.useState({})

  const [ order, setOrder ] = React.useState("asc")
  const [ orderBy, setOrderBy ] = React.useState(null)

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedData = orderBy ? [ ...slicedData ].sort((a, b) => {
    const value1 = parseFloat(a.modelYear?.S)
    const value2 = parseFloat(b.modelYear?.S)
    if (order === "asc") {
      if (value1 < value2) return -1
      if (value1 > value2) return 1
    }
    if (order === "desc") {
      if (value1 > value2) return -1
      if (value1 < value2) return 1
    }
    return 0
  }) : data

  const filterAggregatedData = (data) => {
    const aggregatedData = data.filter(obj => (obj.modelTypeYearLang?.S).startsWith("aggregate"))
    if (aggregatedData == null) {
      setModelAggregation(null)
      return
    }
    const test = aggregatedData.reduce((acc, item) => {
      acc[item.lang.S + item.market.S] = {
        modelList: (item.listModels?.L)?.map(m => m.S),
        listTimestamp: (item.timestampModelList?.N),
        modelEcoGroup: (item.ecoGroupModels?.L)?.map(m => m.S),
        ecoTimestamp: (item.timestampEcoGroup?.N),
        structure: (item.structureModels?.L)?.map(m => m.S),
        structureTimestamp: (item.timestampStructure?.N),
      }
      return acc
    }, {})
    setModelAggregation(test)
    return data.filter(obj => !(obj.modelTypeYearLang?.S).startsWith("list") && !(obj.modelTypeYearLang?.S).startsWith("structure") && !(obj.modelTypeYearLang?.S).startsWith("eco"))
  }

  const filterByLanguage = (result, languages) => {
    const filteredResult = result.filter(obj => languages.includes(obj.lang?.S))
    return filteredResult
  }

  const filterByRange = (result, ranges) => {
    const filteredResult = result.filter(obj => ranges.includes(obj.modelRange?.S))
    return filteredResult
  }

  useEffect(() => {

    if (selectedMarket !== 0) {
      let result = []
      selectedMarket.map(async market => {
        const queryS = new URLSearchParams({
          market: market
        }).toString()
        const tempList = await dashboardData(queryS)
        result = filterAggregatedData(result.concat(tempList))
        if (selectedLanguages.length !== 0) {
          result = filterByLanguage(result, selectedLanguages)
        }
        if (selectedRanges.length !== 0) {
          result = filterByRange(result, selectedRanges)
        }
        setData(result)
        setSlicedData(result.slice(0, rowsPerPage))
      })
    }

  }, [ rowsPerPage, selectedMarket, selectedLanguages, selectedRanges ])

  const handleChangePage = (event, newPage) => {
    let paginatedData = {}
    if (newPage > page) {
      paginatedData = data.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
    } else {
      paginatedData = data.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
    }
    setSlicedData(paginatedData)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    const initialData = data.slice(0, newRowsPerPage)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setSlicedData(initialData)
  }

  return (
    <>
      <TableContainer sx={{ height: 0.9, paddingRight: '20px' }}>
        <Table stickyHeader size="small" aria-label="sticky dense table">
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell align="right">Language</TableCell>
              <TableCell align="right">ModelName</TableCell>
              <TableCell align="right">ModelType</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'ModelYear'}
                  direction={order}
                  onClick={() => handleSortRequest('ModelYear')}
                >ModelYear</TableSortLabel></TableCell>
              <TableCell align="right">ModelRange</TableCell>
              <TableCell align="right">ModelSeries</TableCell>
              <TableCell align="right">BodyType</TableCell>
              <TableCell align="right">Engine</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">PriceDisclaimer</TableCell>
              <TableCell align="right">Availability</TableCell>
              <TableCell align="right">TechnicalData</TableCell>
              <TableCell align="right">EcoData</TableCell>
              <TableCell align="right">PCCDDownload</TableCell>
              <TableCell align="right">PS5Download</TableCell>
              <TableCell align="right">SitecorePush</TableCell>
              <TableCell align="right">SitecorePull</TableCell>
              <TableCell align="right">StructureData</TableCell>
              <TableCell align="right">SingleModel</TableCell>
              <TableCell align="right">ModelListDownload</TableCell>
              <TableCell align="right">EcoGroupDownload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              sortedData.map((model) => <ModelRow
                key={model.modelType?.S + model.modelYear?.S + model.market?.S + model.lang?.S}
                model={model}
                modelAggregation={modelAggregation}
              />)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ 500 ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default ModelsTable
