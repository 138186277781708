import MarketList from './MarketList'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {

  return <>
    <div className="d-flex flex-nowrap w-100">
      <MarketList />
      <Outlet />
    </div>
  </>
}

export default Dashboard
