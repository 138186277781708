import { useEffect, useState } from 'react'
import './MarketTable.css'
import { fetchBff } from './BffClient'
import { MarketTableBody } from './MarketTableBody'
import { useParams } from 'react-router-dom'

export function MarketTable() {

  const [ marketAnalysis, setMarketAnalysis ] = useState(null)

  const [ loading, setLoading ] = useState(true)

  const { stage, market, language } = useParams()

  useEffect(() => {
    setLoading(true)
    setMarketAnalysis(null)
    if (!market || !language) {
      return
    }
    fetchBff(stage, market, language)
      .then(setMarketAnalysis)
      .then(() => setLoading(false))
  }, [ stage, market, language ])

  const prototypeCount = () => marketAnalysis?.models.filter(m => !!m.prototype).length

  const pccdCount = () => marketAnalysis?.models.filter(m => !!m.prototype).length

  const domainCount = () => marketAnalysis?.models.filter(m => !!m.domain).length

  const presentSingleModelCount = () => marketAnalysis?.models.filter(m => !!m.presentSingle).length

  const presentListModelCount = () => marketAnalysis?.models.filter(m => !!m.presentList).length

  const extensionPs5Count = () => marketAnalysis?.models.filter(m => !!m.extensionPs5).length

  const extensionSitecoreCount = () => marketAnalysis?.models.filter(m => !!m.extensionSitecore).length

  const extensionSitecorePullCount = () => marketAnalysis?.models.filter(m => !!m.extensionSitecorePull).length

  const ecoGroupCount = () => marketAnalysis?.models.filter(m => !!m.ecoGroup).length

  const structureCount = () => marketAnalysis?.models.filter(m => !!m.ecoGroup).length

  const priceCount = () => marketAnalysis?.models.filter(m => !!m.presentPrice?.price).length

  const ecoDetailCount = () => marketAnalysis?.models.filter(m => !!m.presentEcoDetail?.wltpKeys.length).length

  const techDetailCount = () => marketAnalysis?.models.filter(m => !!m.presentTechDetail?.fields?.length).length

  const techDetailSCount = () => marketAnalysis?.models.filter(m => !!m.presentTechDetailS?.fields?.length).length

  return (
    <div className="container-fluid scrollarea market-table">
      <h1 className="header">
        {`${market}-${language}`}
      </h1>
      {loading ? (<div className="loader"></div>) :
        (<table className="data-table mpi">
          <thead>
          <tr className="mpi">
            <th className="mpi cell-model-id"><p>Type</p><p>Year</p><p>Engine</p></th>
            <th className="mpi cell-model-id"><p>Range</p><p>Series</p><p>Name</p></th>
            <th className="mpi cell-model-id"><p>Prototype</p><p>({prototypeCount()})</p></th>
            <th className="mpi cell-model-id"><p>Available</p></th>
            <th className="mpi cell-model-source"><p>Pccd</p><p>({pccdCount()})</p></th>
            <th className="mpi cell-model-source"><p>Extension</p><p>PS5</p><p>({extensionPs5Count()})</p></th>
            <th className="mpi cell-model-source"><p>Extension</p><p>Sitecore</p><p>({extensionSitecoreCount()})</p></th>
            <th className="mpi cell-model-source"><p>Extension</p><p>Sitecore<br/>Pull</p><p>({extensionSitecorePullCount()})</p></th>
            <th className="mpi cell-model-domain"><p>Domain</p><p>({domainCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>Single</p><p>({presentSingleModelCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>List</p><p>({presentListModelCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>Price</p><p>({priceCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>EcoDetail</p><p>({ecoDetailCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>TechDetail</p><p>({techDetailCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>TechDetailSS</p><p>({techDetailSCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>EcoGroup</p><p>({ecoGroupCount()})</p></th>
            <th className="mpi cell-model-present"><p>Present</p><p>Structure</p><p>({structureCount()})</p></th>
          </tr>
          </thead>
          {
            marketAnalysis && <MarketTableBody models={marketAnalysis.models}/>
          }
        </table>)
      }
    </div>
  )
}
