import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './MarketList.css'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { fetchBffMarkets } from './BffClient'

const MarketList = () => {

  const currentMarketRef = useRef()

  const marketListRef = useRef()

  const [ marketLanguages, setMarketLanguages ] = useState([])

  const [ marketSearch, setMarketSearch ] = useState('')

  const { stage, market: selectedMarket, language: selectedLanguage } = useParams()

  useEffect(() => {
    fetchBffMarkets(stage)
      .then(markets => markets
        .map(market => market.languages
          .map(language => ({
            language: language,
            market: market.market,
          }))
        )
        .reduce((x, y) => ([ ...x, ...y ]))
      )
      .then(setMarketLanguages)
  }, [ stage ])

  useEffect(() => {
    marketListRef.current.scrollTop = currentMarketRef.current?.offsetTop - marketListRef.current?.offsetTop
  }, [ selectedMarket, selectedLanguage, marketLanguages ])

  const onSearchMarket = e => {
    e.preventDefault()
    setMarketSearch(e.target.value)
  }

  const isSelected = (market, language) => market === selectedMarket && language === selectedLanguage

  const matchesMarketSearch = market => !marketSearch || market.toLowerCase().includes(marketSearch.toLowerCase())

  return <>
    <div className="d-flex flex-column align-items-stretch flex-shrink-0 bg-body-tertiary market-list">
      <form className="col-12 col-lg-auto mb-3 mb-lg-0 " role="search">
        <input className="form-control search-bar"
               value={marketSearch}
               placeholder="Search..."
               onChange={onSearchMarket}
               aria-label="Search"
        />
      </form>
      <div className="list-group list-group-flush border-bottom scrollarea" ref={marketListRef}>
        {
          marketLanguages
            .filter(({ market }) => matchesMarketSearch(market))
            .map(({ market, language }) =>
              <Link to={`./${market}/${language}`}
                    className={`list-group-item py-3 ${isSelected(market, language) && 'highlight'}`}
                    key={`${market}-${language}`}
                    ref={(isSelected(market, language)) ? currentMarketRef : undefined}
              >
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <strong className="mb-1 px-3">{market}</strong>
                  <code className="text-reset px-2">{language}</code>
                </div>
              </Link>
            )
        }
      </div>
    </div>
    <div className="sidebar-divider sidebar-vr h-100"></div>
  </>
}

export default MarketList
