import { Popover, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { preSignUrl } from '../BffClient'
import { env } from '../env'
import Typography from '@mui/joy/Typography'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}))

const initialPopoverState = {
  open: false,
  anchorEl: null,
  lines: [],
}

export const ModelRow = ({ model: ddbModel, modelAggregation }) => {

  const getTimestamp = (aggregatedData, modelType, language, type, market) => {
    if (Object.keys(aggregatedData).length === 0 || !modelType || !language) {
      return
    }
    const mapKey = language + market
    if (type === "list") {
      const modelList = aggregatedData[mapKey]?.modelList;
      if (!modelList) {
        return
      }
      if (modelList.includes(modelType) && aggregatedData[mapKey]?.listTimestamp != null) {
        return convertTimestamp(aggregatedData[mapKey]?.listTimestamp)
      }
    }
    if (type === "structure") {
      const structureList = aggregatedData[mapKey]?.structure;
      if (!structureList) {
        return
      }
      if (structureList.includes(modelType) && aggregatedData[mapKey]?.structureTimestamp != null) {
        return convertTimestamp(aggregatedData[mapKey]?.structureTimestamp)
      }
    }
    if (type === "ecoGroup") {
      const ecoList = aggregatedData[mapKey]?.modelEcoGroup;
      if (!ecoList) {
        return
      }
      if (ecoList.includes(modelType) && aggregatedData[mapKey]?.ecoTimestamp != null) {
        return convertTimestamp(aggregatedData[mapKey]?.ecoTimestamp)
      }
    }
  }

  const convertTimestamp = (timestamp) => {
    if (timestamp == null) {
      return
    }
    const convertedDate = new Date(timestamp * 1)
    const date = convertedDate.toLocaleDateString()
    const time = convertedDate.toLocaleTimeString()
    return date + " " + time
  }

  const model = {
    market: ddbModel.market?.S,
    language: ddbModel.lang?.S,
    modelName: ddbModel.modelName?.S,
    modelType: ddbModel.modelType?.S,
    modelYear: ddbModel.modelYear?.S,
    modelRange: ddbModel.modelRange?.S,
    modelSeries: ddbModel.modelSeries?.S,
    bodyType: ddbModel.bodyType?.S,
    engineType: ddbModel.engineType?.S,
    price: ddbModel.price?.S,
    priceDisclaimerCount: ddbModel.priceDisclaimers?.L?.length,
    pressEmbargo: ddbModel.pressEmbargo?.S,
    iccInvisible: ddbModel.iccInvisible?.BOOL,
    mpiEnabled: ddbModel.mpiEnabled?.BOOL,
    mpiDisabled: ddbModel.mpiDisabled?.BOOL,
    technicalDetail: ddbModel.technicalDetail?.BOOL,
    economyDetail: ddbModel.economyDetail?.BOOL,
    presentationAvailable: ddbModel.presentationAvailable?.BOOL,
    timestampPccd: convertTimestamp(ddbModel.timestampPccd?.N),
    timestampSingleModel: convertTimestamp(ddbModel.timestampSingleModel?.N),
    timestampPs5: convertTimestamp(ddbModel.timestampPs5?.N),
    timestampSitecorePush: convertTimestamp(ddbModel.timestampSitecorePush?.N),
    timestampSitecorePull: convertTimestamp(ddbModel.timestampSitecorePull?.N),
    timestampModelList: getTimestamp(modelAggregation, ddbModel.modelType?.S, ddbModel.lang?.S, "list", ddbModel.market?.S),
    timestampMarketStructure: getTimestamp(modelAggregation, ddbModel.modelType?.S, ddbModel.lang?.S, "structure", ddbModel.market?.S),
    timestampEcoGroup: getTimestamp(modelAggregation, ddbModel.modelType?.S, ddbModel.lang?.S, "ecoGroup", ddbModel.market?.S),
  }

  const [ popover, setPopover ] = useState(initialPopoverState)


  const handleClose = (event) => {
    setPopover(initialPopoverState)
  }

  const handleAvailabilityClick = (event, lines) => {
    setPopover({
        open: true,
        anchorEl: event.currentTarget,
        lines,
      }
    )
  }

  const openPage = ({ bucket, key }) =>
    preSignUrl(bucket, key)
      .then(window.open)

  const openExtension = (source) => {
    const { market, language, modelType, modelYear } = model
    const bucket = `car-model-extension-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}/${source}`
    const confirm = window.confirm(`Open Extension ${source} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentation = (file) => {
    const { market, language } = model
    const bucket = `market-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${file}`
    const confirm = window.confirm(`Open Presentation ${file} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPccd = () => {
    const { market, language, modelType, modelYear } = model
    const bucket = `pccd-car-model-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open PCCD S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentationModel = () => {
    const { market, language, modelType, modelYear } = model
    const bucket = `market-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/models/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open Presentation Model S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const availability = (model) => {
    const today = new Date().getTime()
    const modelPublishDate = new Date(model.pressEmbargo + "T00:00:00").getTime()
    const isAfterBlockingDate = today >= modelPublishDate || model.pressEmbargo === undefined || model.pressEmbargo === null
    const visible = !(model.iccInvisible) || model.mpiEnabled

    return (isAfterBlockingDate && visible && model.presentationAvailable) ? '✅' : '⛔️'
  }

  return (
    <>
      <Popover sx={{
        '& .MuiPaper-root': {
          borderRadius: '0',
          backgroundColor: '#D3D3D3',
          boxShadow: 'none',
          border: 'none'
        }
      }} id={'beautiful-popover'} open={popover.open} anchorEl={popover.anchorEl} onClose={handleClose}
               anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
               transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
        {popover.lines?.map((content) => <Typography sx={{ p: 2 }}>{content}</Typography>)}
      </Popover>
      <StyledTableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        className="table-row"
      >
        <TableCell>{model.market}</TableCell>
        <TableCell align="right">{model.language}</TableCell>
        <TableCell align="right">{model.modelName}</TableCell>
        <TableCell align="right">{model.modelType}</TableCell>
        <TableCell align="right">{model.modelYear}</TableCell>
        <TableCell align="right">{model.modelRange}</TableCell>
        <TableCell align="right">{model.modelSeries}</TableCell>
        <TableCell align="right">{model.bodyType}</TableCell>
        <TableCell align="right">{model.engineType}</TableCell>
        <TableCell align="right">{model.price}</TableCell>
        <TableCell align="right">{model.priceDisclaimerCount}</TableCell>
        <TableCell align="right"
                   onClick={e => handleAvailabilityClick(e, [ `iccInvisible: ${model.iccInvisible}, pressEmbargo: ${model.pressEmbargo}, mpiHidden: ${model.mpiDisabled}, mpiEnabled: ${model.mpiEnabled}` ])}>
          {availability(model)}
        </TableCell>
        <TableCell align="right">{model.technicalDetail ? '✅' : '⛔️'}</TableCell>
        <TableCell align="right">{model.economyDetail ? '✅' : '⛔️'}</TableCell>
        <TableCell align="right"
                   onClick={() => openPccd()}>{model.timestampPccd}</TableCell>
        <TableCell align="right"
                   onClick={() => openExtension('ps5')}>{model.timestampPs5}</TableCell>
        <TableCell align="right"
                   onClick={() => openExtension('sitecore')}>{model.timestampSitecorePush}</TableCell>
        <TableCell align="right"
                   onClick={() => openExtension('sitecore-pull')}>{model.timestampSitecorePull}</TableCell>
        <TableCell align="right"
                   onClick={() => openPresentation('structure')}>{model.timestampMarketStructure}</TableCell>
        <TableCell align="right"
                   onClick={() => openPresentationModel()}>{model.timestampSingleModel}</TableCell>
        <TableCell align="right"
                   onClick={() => openPresentation('models')}>{model.timestampModelList}</TableCell>
        <TableCell align="right"
                   onClick={() => openPresentation('eco-groups')}>{model.timestampEcoGroup}</TableCell>
      </StyledTableRow>
    </>
  )

}
