import { atom } from 'recoil'

const mockMarketsResponse = [
  {
    market: "INT",
    description: [
      "International",
      "WW"
    ],
    languages: [
      "en"
    ]
  },
  {
    market: "PBE",
    description: [
      "Belgium",
      "BE"
    ],
    languages: [
      "en",
      "fr",
      "nl"
    ]
  },
  {
    market: "PBR",
    description: [
      "Brazil",
      "BR"
    ],
    languages: [
      "pt"
    ]
  },
]

const mockModels = {
  "Y1AAA1": [
    {
      market: "INT",
      language: "en",
      modelName: "Some Model 1",
    },
    {
      market: "PCH",
      language: "en",
      modelName: "Some Model 2",
    },
    {
      market: "PCH",
      language: "fr",
      modelName: "Some Model 2",
    },
  ],
  "Y1AAI1": [
    {
      market: "INT",
      language: "en",
      modelName: "Some Model 1",
    },
    {
      market: "PCH",
      language: "en",
      modelName: "Some Model 2",
    },
    {
      market: "PCH",
      language: "fr",
      modelName: "Some Model 2",
    },
  ],
  "Y1AAA4": [ {}, {} ],
}

export const allMarketsState = atom({
  key: 'allMarketsState',
  default: mockMarketsResponse,
})

export const modelsState = atom({
  key: 'modelsState',
  default: mockModels,
})
