import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { allMarketsState, modelsState } from './state-store'
import { selector, useRecoilState, useRecoilValue } from 'recoil'

const allMarketLanguagesValue = selector({
  key: 'allMarketLanguagesValue',
  get: ({ get }) => {
    const allMarkets = get(allMarketsState)
    return allMarkets
      .map(market => market.languages
        .map(language => ({ language, market: market.market })))
      .flat()
  }
})

const cellStyle = {
  width: '5%',
  maxWidth: '5%',
  minWidth: '120px',
}

export const ReleaseTable = () => {

  const selectedModels = [ 'Y1AAA1', 'Y1AAI1' ]

  const marketLanguages = useRecoilValue(allMarketLanguagesValue)

  const [ models ] = useRecoilState(modelsState)

  const row = ({ market, language }) => (
    <TableRow key={`${market}-${language}`}>
      <TableCell sx={{ ...cellStyle, bgcolor: 'lightgray' }}>
        <center>{market}</center>
        <center>{language}</center>
      </TableCell>
      <>
        {
          selectedModels.map(modelType =>
            <TableCell key={modelType} align="right" sx={cellStyle}>
              {
                models[modelType]
                  ?.find(model => model.market === market && model.language === language)
                  ?.modelName
              }
            </TableCell>)
        }
      </>
    </TableRow>
  )

  return (
    <TableContainer sx={{ height: 0.9, paddingRight: '20px' }}>
      <Table stickyHeader size="small" aria-label="sticky dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ ...cellStyle, bgcolor: 'lightgray' }}>Market</TableCell>
            <>
              {
                selectedModels.map(model =>
                  <TableCell key={model} align="right">{model}</TableCell>)
              }
            </>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            marketLanguages
              .map(({ market, language }) => row({ market, language }))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

