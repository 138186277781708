import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const Release911 = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "911")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td className="mpi">{
      `${icon}`
    }</td>  }

  return (
    <div className="scrollarea">
      <table className="data-table mpi">
        <thead>
        <tr className="mpi">
          <th className="mpi"><p>Market Language</p></th>
          <th className="mpi"><p>9921B2</p></th>
          <th className="mpi"><p>9923B2</p></th>
          <th className="mpi"><p>992142</p></th>
          <th className="mpi"><p>992342</p></th>
          <th className="mpi"><p>992442</p></th>
          <th className="mpi"><p>992542</p></th>
          <th className="mpi"><p>992642</p></th>
          <th className="mpi"><p>992830</p></th>
        </tr>
        </thead>
        <tbody className="mpi">
        {releaseStatus.map((item, index) => {
          return <tr className="mpi" key={index}>
            <td className="mpi">
              {item.market + "-" + item.language}
            </td>
            {modelAvailable(item.models.at(0).size)}
            {modelAvailable(item.models.at(1).size)}
            {modelAvailable(item.models.at(2).size)}
            {modelAvailable(item.models.at(3).size)}
            {modelAvailable(item.models.at(4).size)}
            {modelAvailable(item.models.at(5).size)}
            {modelAvailable(item.models.at(6).size)}
            {modelAvailable(item.models.at(7).size)}
          </tr>
        })}
        </tbody>
      </table>
    </div>
  )
}

export default Release911
