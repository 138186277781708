import React from "react"
import ModelsTable from "./ModelsTable"
import MarketSelector from "./filters/MarketSelector"
import Paper from '@mui/material/Paper'
import { LanguageSelector } from "./filters/LanguageSelector";
import { RangeSelector } from "./filters/RangeSelector";

const Dashboard = () => {
  const [ selectedMarket, setSelectedMarket ] = React.useState([])
  const [ possibleSelectedLanguages, setPossibleSelectedLanguages ] = React.useState(['en', 'de'])
  const [ selectedLanguages, setSelectedLanguages ] = React.useState([])
  const [ selectedRanges, setSelectedRanges ] = React.useState([])

  const handleMarketChange = market => {
    console.log(market)
    setSelectedMarket(market)
  }

  const handlePossibleLanguageChange = languages => {
    console.log('possibleLanguages', languages)
    setPossibleSelectedLanguages(languages)
  }

  const handleLanguageChange = languages => {
    console.log('selectedLanguages', languages)
    setSelectedLanguages(languages)
  }

  const handleRangeChange = ranges => {
    console.log('selectedRanges', ranges)
    setSelectedRanges(ranges)
  }

  return <div className="d-flex flex-nowrap w-100">
    <div className="ModelFilter-frame">
      <MarketSelector onMarketSelect={handleMarketChange} onPossibleLanguageChange={handlePossibleLanguageChange}/>
      <LanguageSelector languages={possibleSelectedLanguages} onLanguageChange={handleLanguageChange}/>
      <RangeSelector onRangeChange={handleRangeChange}/>
    </div>
    <React.Fragment>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <ModelsTable selectedMarket={selectedMarket} selectedLanguages={selectedLanguages} selectedRanges={selectedRanges}/>
      </Paper>
    </React.Fragment>
  </div>
}

export default Dashboard
