import Paper from '@mui/material/Paper'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { ReleaseTable } from './ReleaseTable'

export const Releases = () => {

  return (
    <RecoilRoot>
      <div className="d-flex flex-nowrap w-100">
        <div className="ModelFilter-frame">
          Filters
        </div>
        <Paper sx={{ overflow: 'hidden' }}>
          <ReleaseTable></ReleaseTable>
        </Paper>
      </div>
    </RecoilRoot>
  )
}
