import { useEffect, useState } from 'react'
import './MarketTable.css'
import { checkExportTask, createExportTask, fetchBffMarkets, preSignUrl } from './BffClient'
import './PccdExport.css'
import { convertCsvToExcel } from './csv-excel-converter'

export function PccdExport() {

  const [ markets, setMarkets ] = useState([])

  const [ checkedMarkets, setCheckedMarkets ] = useState([])

  const [ loading, setLoading ] = useState(false)

  const [ queryExecutionId, setQueryExecutionId ] = useState(null)

  useEffect(() => {
    fetchBffMarkets('blue')
      .then(markets => markets
        .map(market => market.market)
        .sort()
      )
      .then(setMarkets)
  }, [])

  useEffect(() => {
    if (!queryExecutionId) {
      return
    }
    const interval = setInterval(async () => {
      const result = await checkExportTask(queryExecutionId)
      if (result.queryStatus === 'RUNNING') {
        return
      }
      if (result.queryStatus === 'SUCCEEDED') {
        clearInterval(interval)
        const [ bucket, key ] = result.outputLocation.replace('s3://', '').split('/')
        preSignUrl(bucket, key)
          .then(url => {
            // if (checkedMarkets.length > 20) {
            //   return window.open(url, '_blank')
            // } else {
              return convertCsvToExcel(url, filename())
            // }
          })
          .then(() => setLoading(false))
      }
    }, 3000)
    return () => clearInterval(interval)
  }, [ queryExecutionId ])

  const checkMarket = market => {
    setCheckedMarkets([ ...new Set([ ...checkedMarkets, market ]) ])
  }

  const uncheckedMarket = market => {
    setCheckedMarkets(checkedMarkets.filter(m => m !== market))
  }

  const allMarketsChecked = () => markets.length === checkedMarkets.length

  const setCheckAll = checked => {
    checked ? setCheckedMarkets(markets) : setCheckedMarkets([])
  }

  const createExport = () => {
    console.log('Exporting markets:', checkedMarkets)
    if (checkedMarkets.length === 0) {
      return
    }
    createExportTask(checkedMarkets)
      .then(response => setQueryExecutionId(response.QueryExecutionId))
    setLoading(true)
  }

  return (
    <div className="container-fluid export-page">
      <div className="pt-4 checked-markets">
        <span id="number-of-markets-checked">{checkedMarkets.length} markets checked</span>
        <label id="check-all">
          <input
            type="checkbox"
            className="checkbox"
            onChange={e => setCheckAll(e.target.checked)}
            checked={allMarketsChecked()}
          />
          {allMarketsChecked() ? 'Uncheck' : 'Check'} ALL
        </label>
      </div>
      <div className="markets">
        {
          chunkArray(markets || [], 8)
            .map(marketLine => (
              <div className="market-line" key={marketLine}>
                {
                  marketLine.map(market => (
                    <label className="market py-1" key={market}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={checkedMarkets.includes(market)}
                        onChange={e => e.target.checked ? checkMarket(market) : uncheckedMarket(market)}
                      />
                      {market}
                    </label>
                  ))
                }
              </div>)
            )
        }

      </div>
      <div className="d-grid gap-2 col-3 mx-auto py-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={createExport}
          disabled={checkedMarkets.length === 0}
        >
          Export
        </button>
      </div>
      {
        loading && <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      }
    </div>
  )
}

const chunkArray = (arr, size) =>
  arr.length > size
    ? [ arr.slice(0, size), ...chunkArray(arr.slice(size), size) ]
    : [ arr ];


const filename = () => {
  const date = new Date()
  const formattedTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}`
  return `pccd_attributes_export_${formattedTime}`
}
