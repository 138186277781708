import * as React from 'react'
import Checkbox from '@mui/joy/Checkbox'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'

export const LanguageSelector = ({ languages, onLanguageChange }) => {

  const [ value, setValue ] = React.useState([])

  const checkedLanguageBorderStyle = {
    border: '1px solid',
    borderColor: 'primary.500',
  }

  return (
    <Sheet sx={{ paddingX: 2, paddingTop: 2 }}>
      <Typography id="rank" level="title-sm" sx={{ fontWeight: 'lg', mb: 1.5 }}>
        Languages
      </Typography>
      <div role="group" aria-labelledby="rank">
        <List
          orientation="horizontal"
          wrap
          sx={{
            '--List-gap': '8px',
            '--ListItem-radius': '4px',
            '--ListItem-minHeight': '28px',
            '--ListItem-gap': '4px',
          }}
        >
          {languages.map(
            (item, index) => (
              <ListItem key={item}>
                <Checkbox
                  size="sm"
                  disableIcon
                  overlay
                  label={item}
                  checked={value.includes(item)}
                  variant={value.includes(item) ? 'soft' : 'outlined'}
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelectedLanguages = [ ...value, item]
                      setValue(newSelectedLanguages)
                      onLanguageChange(newSelectedLanguages)
                    } else {
                      const newSelectedLanguages = value.filter((text) => text !== item)
                      setValue(newSelectedLanguages)
                      onLanguageChange(newSelectedLanguages)
                    }
                  }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: checked ? checkedLanguageBorderStyle : {},
                    }),
                  }}
                />
              </ListItem>
            ),
          )}
        </List>
      </div>
    </Sheet>
  )
}
