import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const MacanRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  // Todo: fetch all markets which are available, store in marketLanguages
  const { stage } = useParams()

  useEffect(() => {
    release(stage, "macan")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td className="mpi">{
      `${icon}`
    }</td>  }

  return (
    <div className="scrollarea">
      <table className="data-table mpi">
        <thead>
        <tr className="mpi">
          <th className="mpi"><p>Market Language</p></th>
          <th className="mpi"><p>Macan 4 Electric (XABBB1)</p></th>
          <th className="mpi"><p>Macan Turbo Electric (XABFD1)</p></th>
          <th className="mpi"><p>Macan Electric (XABAA1)</p></th>
          <th className="mpi"><p>Macan 4S Electric (XABDC1)</p></th>
        </tr>
        </thead>
        <tbody className="mpi">
        {releaseStatus.map((item, index) => {
          return <tr className="mpi" key={index}>
            <td className="mpi">
              {item.market + "-" + item.language}
            </td>
            {modelAvailable(item.models.at(0).size)}
            {modelAvailable(item.models.at(1).size)}
            {modelAvailable(item.models.at(2).size)}
            {modelAvailable(item.models.at(3).size)}
          </tr>
        })}
        </tbody>
      </table>
    </div>
  )
}

export default MacanRelease
