import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const Turbo50YearsRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "911Turbo50Year")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td className="mpi">{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table mpi">
      <thead>
      <tr className="mpi">
        <th className="mpi"><p>Market Language</p></th>
        <th className="mpi"><p>992470</p></th>
      </tr>
      </thead>
      <tbody className="mpi">
      {releaseStatus.map((item, index) => {
        return <tr className="mpi" key={index}>
          <td className="mpi">
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default Turbo50YearsRelease
