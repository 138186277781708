import * as React from 'react'
import PropTypes from 'prop-types'
import { useAutocomplete } from '@mui/base/useAutocomplete'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import { useEffect, useState } from "react";
import { fetchBffMarkets } from "../../BffClient";
import './ModelFilter.css'
import { Button } from "@mui/joy";

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
)

const Tag = ({ label, onDelete, ...other }) =>
  (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete}/>
    </div>
  )

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 20px;
    cursor: pointer;
    padding: 4px;
  }
`,
)

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
  transparency: 1;
  font-size: 14px;
  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
)

export default function MarketSelector({ onMarketSelect, onPossibleLanguageChange }) {
  const [ markets, setMarkets ] = useState([])
  const [ selectedMarkets, setSelectedMarkets ] = useState([])
  const maxMarketSelection = 4

  const handleSelect = () => {
    console.log(selectedMarkets)
    const markets = selectedMarkets.map(market => market.market)
    const languages = selectedMarkets.flatMap(market => market.languages)
    const uniqueLanguages = [...new Set(languages)]
    onMarketSelect(markets)
    onPossibleLanguageChange(uniqueLanguages)
  }

  useEffect(() => {
    fetchBffMarkets("blue")
      .then(markets => markets
      )
      .then(setMarkets)
  }, [])
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: [],
    multiple: true,
    options: markets,
    onChange: (event, newValue) => {
      if (newValue.length <= maxMarketSelection) {
        setSelectedMarkets(newValue)
      }
    },
    getOptionLabel: (option) => option.market,
  })

  return (
    <div className="ModelFilter-frame">
      <Sheet sx={{ paddingX: 2, paddingTop: 2 }}>
        <Typography id="rank" level="title-sm" sx={{ fontWeight: 'lg', mb: 1.5 }}>
          Markets
        </Typography>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            {selectedMarkets
              .map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index })
                return <StyledTag key={key} {...tagProps} label={option.market}/>
              })}
            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 && selectedMarkets.length < maxMarketSelection ? (
          <Listbox {...getListboxProps()}>
            {
              groupedOptions
                .map((option, index) => {
                  const { key, ...optionProps } = getOptionProps({ option, index })
                  return (
                    <li key={key} {...optionProps}>
                      <span>{option.market}</span>
                      <CheckIcon fontSize="small"/>
                    </li>
                  )
                })}
          </Listbox>
        ) : null}
        <div className="ModelFilter-frame">
          <Button onClick={handleSelect} ref={setAnchorEl} className={focused ? 'focused' : ''} style={{
            backgroundColor: 'black',
            color: 'white',
            marginTop: '10px',
          }}>Search</Button>
        </div>
      </Sheet>
    </div>
)
}
